export default [
    {
        key: 'en',
        value: 'English'
    },
    {
        key: 'es',
        value: 'Español'
    },
    {
        key: 'pt',
        value: 'Português'
    },
    {
        key: 'ja',
        value: '日本語'
    },
    {
        key: 'ko',
        value: '한국인'
    },
    {
        key: 'zh-CN',
        value: '中文'
    }
]